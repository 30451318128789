
if( $('.scroll').length > 0 ){

	var elem = $.jInvertScroll(['.scroll'],        // an array containing the selector(s) for the elements you want to animate
	    {
	    height: 9000,                   // optional: define the height the user can scroll, otherwise the overall length will be taken as scrollable height
	    onScroll: function(percent) {   //optional: callback function that will be called when the user scrolls down, useful for animating other things on the page
	        // console.log(percent);
	    }
	});

}

var debounce = function(wait, immediate, func) {
	var timeout;

	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

var addEndEvent = debounce(200, false, function(e) {
	$(this).trigger(e.type + 'End');
});

var is_mobile = function() {

	if ($(window).width() < 768) {
	    if( elem ) elem.destroy();
	    $('html').addClass('is_mobile');
	  }
	  else {
	    if( elem ) elem.reinitialize();
	    $('html').removeClass('is_mobile');
	  }

};



	/*
	##     ##  ########   ##    ##   ##     ##  
	###   ###  ##         ###   ##   ##     ##  
	#### ####  ##         ####  ##   ##     ##  
	## ### ##  ######     ## ## ##   ##     ##  
	##     ##  ##         ##  ####   ##     ##  
	##     ##  ##         ##   ###   ##     ##  
	##     ##  ########   ##    ##    #######   
	*/

	$('.menu-item a').click(function(e) {
		$('body').removeClass('nav-opened');
	});

	$('.nav-main__open').click(function(e) {
		e.preventDefault();
		$('body').addClass('nav-opened');
	});

	$('.nav-main__close').click(function(e) {
		e.preventDefault();
		$('body').removeClass('nav-opened');
	});


/*
 ######    ##     ##  ########   ##     ##  ########   ##    ##   ##     ##  
##    ##   ##     ##  ##     ##  ###   ###  ##         ###   ##   ##     ##  
##         ##     ##  ##     ##  #### ####  ##         ####  ##   ##     ##  
 ######    ##     ##  ########   ## ### ##  ######     ## ## ##   ##     ##  
      ##   ##     ##  ##     ##  ##     ##  ##         ##  ####   ##     ##  
##    ##   ##     ##  ##     ##  ##     ##  ##         ##   ###   ##     ##  
 ######     #######   ########   ##     ##  ########   ##    ##    #######   
*/


	/*  SUBMENU STICKY  */

	// var pageHeight = $('.page-container').height(); 


	var scene_sticky  = null;
	var submenuSticky = null;
	subnavSticky = function(){

		if( $(window).width() > 992 ){

				
			if( submenuSticky === null ){

				$('.page-container').imagesLoaded({ background: true }, function(){

					durationSticky = $('.page-container').height() - $('.pattern__container').height() - $('#pinned-element').height() - $('#submenu__fixed').height();

					submenuSticky = new ScrollMagic.Controller();

					// var scene = new ScrollMagic.Scene({triggerElement: "#pinned-element", triggerHook:0, duration: durationSticky})
					scene_sticky = new ScrollMagic.Scene({triggerElement: "#pinned-element", triggerHook: 'onLeave', offset:-67, duration: durationSticky})
										.setClassToggle('#pinned-element', 'sticky') // add class toggle
										.setPin("#pinned-element")
										// .addIndicators({name: "1 (duration:"+durationSticky+")"}) // add indicators (requires plugin)
										.addTo(submenuSticky);
				});
			}

		}else{


			if( submenuSticky ){
				submenuSticky.destroy(true);
				scene_sticky.destroy(true);
				submenuSticky = null;
				scene_sticky = null;
			}

		}


	};


	var controller = new ScrollMagic.Controller();

	durationResidence = $('#residence').outerHeight();
	durationLocalisation     = $('#localisation').outerHeight();
	durationPrestation     = $('#prestation').outerHeight(); 

	// build scenes
	new ScrollMagic.Scene({triggerElement: "#residence"})
					.setClassToggle("#menu-residence", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationResidence)
					.addTo(controller);
	new ScrollMagic.Scene({triggerElement: "#localisation"})
					.setClassToggle("#menu-localisation", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationLocalisation)
					.addTo(controller);
	new ScrollMagic.Scene({triggerElement: "#prestation"})
					.setClassToggle("#menu-prestation", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationPrestation)
					.addTo(controller);


	var submenuSociete = new ScrollMagic.Controller();

	durationQualite = $('#qualite').outerHeight();
	durationCreativite     = $('#creativite').outerHeight();
	durationBatir     = $('#batir').outerHeight(); 
	durationLieux     = $('#lieux').outerHeight(); 
	durationEngagement     = $('#engagement').outerHeight(); 

	// build scenes
	new ScrollMagic.Scene({triggerElement: "#qualite"})
					.setClassToggle("#menu-qualite", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationQualite)
					.addTo(submenuSociete);
	new ScrollMagic.Scene({triggerElement: "#creativite"})
					.setClassToggle("#menu-creativite", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationCreativite)
					.addTo(submenuSociete);
	new ScrollMagic.Scene({triggerElement: "#batir"})
					.setClassToggle("#menu-batir", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationBatir)
					.addTo(submenuSociete);
	new ScrollMagic.Scene({triggerElement: "#lieux"})
					.setClassToggle("#menu-lieux", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationLieux)
					.addTo(submenuSociete);
	new ScrollMagic.Scene({triggerElement: "#engagement"})
					.setClassToggle("#menu-engagement", "current") // add class toggle
					// .addIndicators() // add indicators (requires plugin)
					.duration(durationEngagement)
					.addTo(submenuSociete);


$('.localscroll').localScroll({
	hash: true
});
$.localScroll.hash();


/*
 ######    ##         ####   ######    ##    ##   
##    ##   ##          ##   ##    ##   ##   ##    
##         ##          ##   ##         ##  ##     
 ######    ##          ##   ##         #####      
      ##   ##          ##   ##         ##  ##     
##    ##   ##          ##   ##    ##   ##   ##    
 ######    ########   ####   ######    ##    ##   
*/

	$('.header__container').slick({
		slidesToShow: 1, 
	    slidesToScroll: 1, 
	    autoplay: true, 
	    speed: 500,
	    autoplaySpeed: 6000,
		arrows: true,
	    prevArrow: $(".slick-prev"),
	    nextArrow: $(".slick-next")
	});


	$('.slider__container').slick({
		slidesToShow: 1, 
        slidesToScroll: 1, 
  		fade: true,
  		speed:1000,
        autoplay: true, 
        autoplaySpeed: 10000,
		arrows: true,
       prevArrow: $(".slick-prev"),
       nextArrow: $(".slick-next")
	});




/*
 ######    ########   ####   ######    ##    ##   ##    ##   
##    ##      ##       ##   ##    ##   ##   ##     ##  ##    
##            ##       ##   ##         ##  ##       ####     
 ######       ##       ##   ##         #####         ##      
      ##      ##       ##   ##         ##  ##        ##      
##    ##      ##       ##   ##    ##   ##   ##       ##      
 ######       ##      ####   ######    ##    ##      ##      
*/
$('.popup-with-form').magnificPopup({
	type: 'inline',
	preloader: false,
	focus: '#name',

	// When elemened is focused, some mobile browsers in some cases zoom in
	// It looks not nice, so we disable it:
	callbacks: {
		beforeOpen: function() {
			if($(window).width() < 700) {
				this.st.focus = false;
			} else {
				this.st.focus = '#name';
			}
		}
	}
});


	/*
	########   ########   ##     ##  ########      ###     ##         
	##     ##  ##         ##     ##  ##           ## ##    ##         
	##     ##  ##         ##     ##  ##          ##   ##   ##         
	########   ######     ##     ##  ######     ##     ##  ##         
	##   ##    ##          ##   ##   ##         #########  ##         
	##    ##   ##           ## ##    ##         ##     ##  ##         
	##     ##  ########      ###     ########   ##     ##  ########   
	*/


	var initScrollAnimations = function() {
		var controllerScroll = new ScrollMagic.Controller();
		
		$('.page .scroll-reveal').each(function() {
			var element = $(this);
			var offset  = element.offset().top;
			var windowHeight  = $(window).height();
			var scene   = new ScrollMagic.Scene({
				triggerHook: "onLeave",
				offset: offset - ( windowHeight * 0.85 ),
				reverse: false
			});
			scene.on('start', function(e) {
				if (e.scrollDirection != 'REVERSE') {
					element.trigger('reveal');
					scene.remove();
				}
			});
			scene.addTo(controllerScroll);
		});	
		$('.page').data('scrollController', controllerScroll);
	};

	
	var scrollRevealHandler = function() {
		var element = $(this);

		if (element.hasClass('is-revealed'))
			return;

		if (element.is('.text')) {
			splitTitle = new SplitText(element.find('span'),{
				type:       'words',
				wordsClass: 'split-word'
			});

			lines = element.find('span');
			words = element.find('.split-word');

			words.css({
				opacity: 0
			});
			element.find('span').css({
				opacity: 1
			});

			element.addClass('is-revealed');

			tl = new TimelineLite();
			tl.pause();

			tl.fromTo(arrow, 1, {
				alpha: 0
			}, {
				alpha: 1,
			});

			lines.each(function(index) {
				var line = $(this);
				tl.fromTo(line.find('.split-word'), 0.8, {
					alpha: 0,
					top: line.height()
				}, {
					alpha: 1,
					top: 0,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.call(function() {
				splitTitle.revert();
			});
			tl.play();

		} else if (element.is('.lined-text')) {
			splitTitle = new SplitText(element.find('p'),{
				type:       'lines',
				linesClass: 'split-line'
			});

			lines = element.find('.split-line');

			lines.css({
				opacity: 0
			});
			element.find('p').css({
				opacity: 1
			});

			element.addClass('is-revealed');

			tl = new TimelineLite();
			tl.pause();

			tl.fromTo(arrow, 1, {
				alpha: 0
			}, {
				alpha: 1,
			});

			lines.each(function(index) {
				var line = $(this);
				tl.fromTo( line, 0.8, {
					alpha: 0,
					top: line.height()
				}, {
					alpha: 1,
					top: 0,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.call(function() {
				splitTitle.revert();
			});
			tl.play();

		} else if (element.is('.editor-text')) {
			p = $(this).find('p, li');
					
			element.addClass('is-revealed');

			p.css({
				opacity: 0
			});

			tl = new TimelineLite();
			tl.pause();
			
			p.each(function(index) {
				tl.fromTo($(this), 0.8, {
					alpha: 0,
					y: 20
				}, {
					alpha: 1,
					y: 0,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.play();

		} else if (element.is('.scroll-title')) {
		
			element.css({
				opacity: 1
			});

			element.addClass('is-revealed');

			tl = new TimelineLite();
			tl.pause();

			tl.fromTo( element, 0.8, {
				alpha: 0,
			}, {
				alpha: 1,
				ease: Power3.easeOut
			});
			tl.play();

		} else if (element.is('.block-reveal')) {
					
			element.addClass('is-revealed');

			element.css({
				opacity: 0
			});

			tl = new TimelineLite();
			tl.pause();
			
			element.each(function(index) {
				tl.fromTo($(this), 0.8, {
					alpha: 0
				}, {
					alpha: 1,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.play();

		} else if (element.is('.stagger-show')) {
			element.addClass('is-revealed');

			square = $(this).find('.stagger-show-item');

			var duration = 0.4;
			var stagger  = 0.2;
			var animationClass = 'show';
			TweenMax.staggerTo(square, duration, {className: '+=' + animationClass}, stagger);
		}
	};


/*
########      ###     ########      ###     ##         ##            ###     ##     ##  
##     ##    ## ##    ##     ##    ## ##    ##         ##           ## ##     ##   ##   
##     ##   ##   ##   ##     ##   ##   ##   ##         ##          ##   ##     ## ##    
########   ##     ##  ########   ##     ##  ##         ##         ##     ##     ###     
##         #########  ##   ##    #########  ##         ##         #########    ## ##    
##         ##     ##  ##    ##   ##     ##  ##         ##         ##     ##   ##   ##   
##         ##     ##  ##     ##  ##     ##  ########   ########   ##     ##  ##     ##  
// */
var ctrlParallax;
var sceneParallax = [];
var initParallax = function() {
	if ( $(window).width() > 0 ) {
		ctrlParallax = new ScrollMagic.Controller();
		$('body').addClass('ctrlParallax');
	}

	$('.parallax').each(function( index ) {
		$(this).css({ transform: '' });
		
		if ( $(window).width() > 0 ) {
			var start      = $(this).attr( 'data-start' );
			var end        = $(this).attr( 'data-end' );
			var direction  = $(this).attr( 'data-direction' );
			if ( start     === undefined ) start = 0;
			if ( end       === undefined ) end = 0;
			if ( direction === undefined ) direction = "vertical";

			var windowHeight  = $(window).height();
			var elementOffset = $(this).offset().top;
			var elementHeight = $(this).outerHeight();
			var offset        = ( elementOffset - windowHeight );
			
			var tween;
			if ( direction == 'horizontal' ) {
				tween = TweenMax.fromTo( this, 1, { x: start }, { x: end, ease: Power0.easeNone } );
			} else {
				tween = TweenMax.fromTo( this, 1, { y: start }, { y: end, ease: Power0.easeNone } );
			}
			
			sceneParallax[index] = new ScrollMagic.Scene({
				offset: offset,
				triggerHook: 'onEnter'
			})
			.setTween( tween )
			.addTo( ctrlParallax );
		}
	});
};


var resizeParallax = function() {
	if ( $(window).width() > 0 )  {
		if ( ! $('body').hasClass('ctrlParallax') ) {
			initParallax();
		} else {
			ctrlParallax.enabled(true);
			ctrlParallax.update(true);
		}
	} else {
		if ( $('body').hasClass('ctrlParallax') ) {
			$('body').removeClass('ctrlParallax');
			ctrlParallax.destroy(true);
		}
	}

	if ( $(window).width() > 0 ) {
		if ( ctrlParallax.enabled() ) {
			$('.parallax').each(function( index ) {
			
				var elementDuration  = $(this).attr( 'data-duration' );
				if ( elementDuration === undefined ) elementDuration = 1;

				var windowHeight  = $(window).height();
				var elementOffset = $(this).offset().top;
				var elementHeight = $(this).outerHeight();
				var offset        = ( elementOffset - windowHeight );
				var duration      = ( windowHeight  + elementHeight );

				sceneParallax[index].duration(duration);
				sceneParallax[index].offset(offset);
				sceneParallax[index].update(true);
			}); 
		} 
	} else {
		$('.parallax').each(function( index ) {
			$(this).css({ transform: '' });
		});
	}	
};




var resizeHandler = function() {
	// is_mobile();
	resizeParallax();
	$(window).trigger('scroll');
	initScrollAnimations();
	subnavSticky();
};

// $(window).resize(function() {
// 	if ($(window).width() < 768) {
// 	    if( elem ) elem.destroy();
// 	    $('html').addClass('is_mobile');
// 	}else {
// 	    if( elem ) elem.reinitialize();
// 	    $('html').removeClass('is_mobile');
// 	}
// });


var bindEvents = function() {
	
	$(window).on('resize orientationchange', addEndEvent);
	$(window).on('resizeEnd', resizeHandler);
	$(window).on('scroll', scrollHandler);
	$('body').on('reveal', '.scroll-reveal', scrollRevealHandler);
};

var scrollHandler = function() {
	var scrollTop = $(window).scrollTop();
	/* EXEMPLE */
	if (scrollTop > 0) {
		$('.scroll-top').addClass('scrolled-btn');
		$('.header').addClass('scrolled');
		$('.logo').addClass('scrolled');
		$('.main-nav').addClass('scrolled'); 
		$('.menu-item__white').addClass('scrolled');
		$('.logo-container').addClass('scrolled');
		$('.menu-item a').addClass('scrolled');
	}

	else {
		$('.scroll-top').removeClass('scrolled-btn');
		$('.header').removeClass('scrolled');
		$('.logo').removeClass('scrolled');
		$('.main-nav').removeClass('scrolled');
		$('.menu-item__white').removeClass('scrolled');
		$('.logo-container').removeClass('scrolled');
		$('.menu-item a').removeClass('scrolled');
	}

};

$(document).ready(function() {
	// $(window).on('resize orientationchange', addEndEvent);
	// $(window).on('resizeEnd', resizeHandler);
	// $(window).on('scroll', scrollHandler);
	bindEvents();
	$(window).trigger('resize');



	/*
 ######       ###     ##         ##         ########   ########   ##    ##   
##    ##     ## ##    ##         ##         ##         ##     ##   ##  ##    
##          ##   ##   ##         ##         ##         ##     ##    ####     
##   ####  ##     ##  ##         ##         ######     ########      ##      
##    ##   #########  ##         ##         ##         ##   ##       ##      
##    ##   ##     ##  ##         ##         ##         ##    ##      ##      
 ######    ##     ##  ########   ########   ########   ##     ##     ##      
*/

	$('.popup-gallery').magnificPopup({
		delegate: 'div',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
		}
	});
	


	/*
	 ######     #######    #######   ##    ##   ####  ########   
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##         ##     ##  ##     ##  #####       ##   ######     
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	 ######     #######    #######   ##    ##   ####  ########   
	*/

	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	// test mobile et reinitialisation scroll horizontal
	is_mobile();
	

   //  if ($(window).width() <= 768) {
	  //   $('html').addClass('is_mobile');
	  // }
	  // else {
	  //   $('html').removeClass('is_mobile');
	  // }
	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	$('.contact-js').on('click', function(e) {
		e.preventDefault();
		var target        = $(this).attr('href');
		var pos           = $('body').height();
		$('html, body').animate({ scrollTop: pos +'px'}, 800);
	});

	$('.logo a').on('click', function(e) {
		e.preventDefault();
		var target        = $(this).attr('href');
		var pos           = $('body').height();
		$('html, body').animate({ scrollTop: pos -'px'}, 800);
	});

	// $('#contact-js').click(function(){

	//     $('html, body').animate({
	//         scrollTop: $( $(this).attr('href') ).offset().top
	//     }, 500);

 //    return false;
	// });

	// /*
	//  ######     #######   ##    ##   ########      ###      ######    ########   
	// ##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	// ##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	// ##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	// ##         ##     ##  ##  ####      ##      #########  ##            ##      
	// ##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	//  ######     #######   ##    ##      ##      ##     ##   ######       ##      
	// */

	if (!String.prototype.trim) {
		(function() {
			// Make sure we trim BOM and NBSP
			var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
			String.prototype.trim = function() {
				return this.replace(rtrim, '');
			};
		})();
	}

	[].slice.call( document.querySelectorAll( '.form__container input' ) ).forEach( function( inputEl ) {
		// in case the input is already filled..
		if( inputEl.value.trim() !== '' ) {
			classie.add( inputEl.parentNode, 'input--filled' );
		}

		// events:
		inputEl.addEventListener( 'focus', onInputFocus );
		inputEl.addEventListener( 'blur', onInputBlur );
	} );

	function onInputFocus( ev ) {
		classie.add( ev.target.parentNode, 'input--filled' );
	}

	function onInputBlur( ev ) {
		if( ev.target.value.trim() === '' ) {
			classie.remove( ev.target.parentNode, 'input--filled' );
		}
	}

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.closest('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.closest('li').removeClass('error');
		}
	});

	$("#fContact").submit(function(){
		if($(this).validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/contact/",
				success : function(retour) {
					// gtag('event', 'submit', {
					//   'event_category': 'form',
					//   'event_label': 'Contact'
					// });
					div.empty().append(retour);
				}
			});
		}
		return false;
	});

	$("#fRappel").submit(function(){
		if($(this).validationEngine('validate')){
			var data = $(this).serialize() + '&programme=' + $('.page-container[data-programme]').data('programme') + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/callback/",
				success : function(retour) {
					// gtag('event', 'submit', {
					//   'event_category': 'form',
					//   'event_label': 'Demande de rappel'
					// });
					div.empty().append(retour);
				}
			});
		}
		return false;
	});

	$("#fPlaquette").submit(function(){
		if($(this).validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">demande en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/plaquette/",
				success : function(retour) {
					// gtag('event', 'download', {
					//   'event_category': 'plaquette',
					//   'event_label': window.location.pathname
					// });
					div.empty().append(retour);
				}
			});
		}
		return false;
	});

	/*
	##          #######    ######    ####  ##    ##   
	##         ##     ##  ##    ##    ##   ###   ##   
	##         ##     ##  ##          ##   ####  ##   
	##         ##     ##  ##   ####   ##   ## ## ##   
	##         ##     ##  ##    ##    ##   ##  ####   
	##         ##     ##  ##    ##    ##   ##   ###   
	########    #######    ######    ####  ##    ##   
	*/
	$("#fLogin").submit(function(){

        if($("#fLogin").validationEngine('validate')){

            var data = $(this).serialize() + '&act=login';
            var div  = $(this).find('.retour').removeClass('retour--error').show().empty().html('envoi en cours...');

            $.ajax({
                type    : "POST",
                data    : data,
                url     : "/login/",
                success : function(retour) {
                	try {
				        var data = JSON.parse(retour);
				    	if( data.error ) div.addClass('retour--error').html(data.error);
				    	if( data.redirect ) window.location = data.redirect;
				    } catch (e) {
				        return false;
				    }
                }
            });
        }
        return false;
    });

    $('.submenu__map').mcubeGmaps({
		bounds:  false, // default : true
		overlay: false, // default : true
		styles:  [{"featureType": "water","elementType": "geometry","stylers": [{"color": "#e9e9e9"},{"lightness": 17}]},{"featureType": "landscape","elementType": "geometry","stylers": [{"color": "#f5f5f5"},{"lightness": 20}]},{"featureType": "road.highway","elementType": "geometry.fill","stylers": [{"color": "#ffffff"},{"lightness": 17}]},{"featureType": "road.highway","elementType": "geometry.stroke","stylers": [{"color": "#ffffff"},{"lightness": 29},{"weight": 0.2}]},{"featureType": "road.arterial","elementType": "geometry","stylers": [{"color": "#ffffff"},{"lightness": 18}]},{"featureType": "road.local","elementType": "geometry","stylers": [{"color": "#ffffff"},{"lightness": 16}]},{"featureType": "poi","elementType": "geometry","stylers": [{"color": "#f5f5f5"},{"lightness": 21}]},{"featureType": "poi.park","elementType": "geometry","stylers": [{"color": "#dedede"},{"lightness": 21}]},{"elementType": "labels.text.stroke","stylers": [{"visibility": "on"},{"color": "#ffffff"},{"lightness": 16}]},{"elementType": "labels.text.fill","stylers": [{"saturation": 36},{"color": "#333333"},{"lightness": 40}]},{"elementType": "labels.icon","stylers": [{"visibility": "off"}]},{"featureType": "transit","elementType": "geometry","stylers": [{"color": "#f2f2f2"},{"lightness": 19}]},{"featureType": "administrative","elementType": "geometry.fill","stylers": [{"color": "#fefefe"},{"lightness": 20}]},{"featureType": "administrative","elementType": "geometry.stroke","stylers": [{"color": "#fefefe"},{"lightness": 17},{"weight": 1.2}]}]
	});


	/*
	########   ####  ########   ##     ##  ####  ########    ######    
	##          ##      ##      ##     ##   ##   ##     ##  ##    ##   
	##          ##      ##      ##     ##   ##   ##     ##  ##         
	######      ##      ##      ##     ##   ##   ##     ##   ######    
	##          ##      ##       ##   ##    ##   ##     ##        ##   
	##          ##      ##        ## ##     ##   ##     ##  ##    ##   
	##         ####     ##         ###     ####  ########    ######    
	*/

	$(".editor-text").fitVids();
	


	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/

	var closeForm = function(){
		$('.form-container').removeClass('form-container--show');
		var close = setTimeout(function(){
			$("#contact").show();
			$(".form-callback").hide();
		}, 1000);
	};

	$(".btn-form").click(
		function() {
			$('.form-container').toggleClass('form-container--show');
		}
	);

	$(".form-cross").click(
		function() {
			closeForm();
		}
	);


	/*
	########    #######   ########   ##     ##  ########   
	##     ##  ##     ##  ##     ##  ##     ##  ##     ##  
	##     ##  ##     ##  ##     ##  ##     ##  ##     ##  
	########   ##     ##  ########   ##     ##  ########   
	##         ##     ##  ##         ##     ##  ##         
	##         ##     ##  ##         ##     ##  ##         
	##          #######   ##          #######   ##         
	*/
	$('.js-popup-realisation').magnificPopup({
		type: 'inline',
		preloader: false
	});

	$('[data-popup]').click(function(){
		$(this).find('.js-popup-realisation').trigger('click');
	});

	$('.iframe-popup').magnificPopup({
		disableOn: 700,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false,
		callbacks: {
			open: function() {
				let mfpop = document.querySelector('.mfp-iframe-scaler');
				let iframe = mfpop.querySelector('iframe');
				let element = document.createElement('span');
				element.classList.add('fullscreen');
				element.innerHTML = "[plein écran]";
				mfpop.append(element);
				element.addEventListener('click', () => {
					if (iframe.requestFullscreen) {
						iframe.requestFullscreen();
					} else if (iframe.webkitRequestFullscreen) {
						iframe.webkitRequestFullscreen();
					} else if (iframe.mozRequestFullScreen) {
						iframe.mozRequestFullScreen();
					} else if (iframe.msRequestFullscreen) {
						iframe.msRequestFullscreen();
					}
				});
			}
		}
	});

});
