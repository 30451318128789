/**
 * jQuery stuff
 */
$(document).ready(function() {

	if( $('body').hasClass('espace-prescripteur') || $('.login').length > 0 ){
		$('.logo a').unbind('click').click(function(){
			window.location = "/";			
		});

		setInterval(function(){ reload_page(); },30*60000);
	}

    //  GOOGLE MAP POUR ESPACE PRESCRIPTEUR //
	if($("#map-presc").length > 0) {

		var div       = $("#map-presc");
		var draggable = false;
		var $lat      = div.data("lat");
		var $lng      = div.data("lng");
		var $zoom     = div.data("zoom");

		$.fn.matchHeight._afterUpdate = function(event, groups) {
			var map = new GMaps({
				zoomControlOpt: {
	            style : 'SMALL',
	            position: 'TOP_RIGHT'
	        },

				options: {
				'styles': 

				[
				  {
				    "featureType": "administrative",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "landscape",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "poi",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "road.local",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "road.highway",
				    "stylers": [
				      { "invert_lightness": true },
				      { "hue": "#ff5e00" },
				      { "lightness": 39 },
				      { "saturation": -84 }
				    ]
				  },{
				    "featureType": "road.arterial",
				    "stylers": [
				      { "lightness": 50 },
				      { "hue": "#ff5500" },
				      { "saturation": -87 }
				    ]
				  },{
				    "featureType": "transit",
				    "stylers": [
				      { "hue": "#ffbb00" }
				    ]
					  },{
				    "featureType": "water",
				    "stylers": [
				      { "color": "#7da7d9" }
				    ]
				  },{
				  }
				]

			},

				div: '#map-presc',
				lat: $lat,
				lng: $lng,
				zoom: parseInt($zoom),
				scrollwheel: false,
				zoom_changed: function() {
					$zoom.val(this.getZoom());
				}
			});

			var marker = map.addMarker({
				lat: $lat,
				lng: $lng,
				icon: '/images/bulle.png',
				draggable: false,
				position_changed: function() {
					$lat.val(this.getPosition().lat());
					$lng.val(this.getPosition().lng());
				}
			});
		}

		
	}

	$('.md-trigger').magnificPopup({
		type: 'inline',
		preloader: false,
		callbacks: {
			beforeOpen: function() {
				// if($(window).width() < 700) {
				// 	this.st.focus = false;
				// } else {
				// 	this.st.focus = '#name';
				// }
			}
		}
	});

	$(".md-trigger").click(function(e){
    	e.preventDefault();
    	$("#fOption input, #fOption textarea").val('');
    	$("#fOption input[name='~lot']").val( $(this).data('lot') );
    	$(".presc--modal__number").html( $(this).data('num') );
    });

    $(".md-close").click(function(){
    	$("#fOption").show();
    	$(".retour").hide();
    });

    $("#fOption").submit(function(){

        if($("#fOption").validationEngine('validate')){

            var data      = $(this).serialize() + '&act=envoi';
            var div       = $(this).hide();
            var divRetour = $(this).next(".retour").show().html('<div class="form-return">envoi en cours...</div>');

            $.ajax({
                type    : "POST",
                data    : data,
                url     : '/option/',
                success : function(retour) {
                    divRetour.empty().append(retour);
                    setTimeout(function(){ 
                    	$.magnificPopup.close();
                    	div.show();
                    	divRetour.html('');
                    }, 2000);
                }
            });
        }
        return false;
    });

    $("#fDenonciation").submit(function(){

        if($("#fDenonciation").validationEngine('validate')){

            var data      = $(this).serialize() + '&act=denonciation';
            var div       = $(this).hide();
            var divRetour = $(this).next(".retour").show().html('<div class="form-return">envoi en cours...</div>');

            $.ajax({
                type    : "POST",
                data    : data,
                url     : '/denonciation/',
                success : function(retour) {
                    divRetour.empty().append(retour);
                    setTimeout(function(){ 
                    	$.magnificPopup.close(); 
                    	div.show();
                    	divRetour.html('');
                    }, 2000);
                }
            });
        }
        return false;
    });

    $('.js-ep-filter-email').on('change', function(){
    	var email = $(this).val();
		$('.presc--tableau tbody tr').show();
		$('.presc--tableau tbody tr.no-lot-prescripteur').hide();
		if( email != 0 ) {
			$('.presc--tableau tbody tr[data-prescripteur!="' + email + '"]').hide();
			$('.presc--tableau').each(function(){
				if( $('tbody tr:visible', this).length == 0 ){
					$('tbody tr.no-lot-prescripteur', this).show().find('span').html(email);
				}
			});
		}
	});

    $('.presc--tableau .typologie').each(function(){
    	if( $(this).next('tr').hasClass('typologie') ){
    		$(this).hide();
		}
	});

});

function reload_page()
{
	window.location.reload(true);
}
