/*
 * Copyright (C) 2018 mcube (contact@mcube.fr)
 * 
 * This work is licensed under the Creative Commons
 * Attribution 3.0 Unported License. To view a copy
 * of this license, visit
 * http://creativecommons.org/licenses/by/3.0/.
 * 
 * When using this software you use it at your own risk. We hold
 * no responsibility for any damage caused by using this plugin
 * or the documentation provided.
 */

var cookieJS = [];
cookieJS.tech = '';
cookieJS.perf = "" +
	"<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
	"new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
	"j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
	"'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
	"})(window,document,'script','dataLayer','GTM-P4XFPFL');</script>";

cookieJS.perso  = '';
cookieJS.social = '';


(function($) {
	$.cookieConsent = function( opts, val ) {
		var defaults = {
			cookieList: ["tech","perf","perso","social"],
			expireDays: 365, //Number of days for cookieBar cookie to be stored for
			effect: 'fade', //Options: slide, fade, hide
			element: 'body', //Element to append/prepend cookieBar to. Remember "." for class or "#" for id.
			domain: String(window.location.hostname), //Location of privacy policy
			referrer: String(document.referrer) //Where visitor has come from
		};
		var options = $.extend( defaults, opts );

		// Param
		var lang        = $("html").attr("lang");
		var cookieValue = 'done:0!all:0!';
		var update      = [];

		// Par defaut aucun changement
		for ( var i = 0; i < options.cookieList.length; i++ ) {
			update[options.cookieList[i]] = false;
		}

		var showBar = function( settings ) {

			$.ajax({
				url: "/cookie/"+lang+".html",
				// data: {	action: 'mcube_rgpd_cookiebar' },
				success: function( retour ) {

					//alert(retour);
					$('body').append( retour );

					if ( $.cookie( 'cookie_consent' ) ) {
						cookieValue = $.cookie( 'cookie_consent' );
					}

					options.cookieList.forEach( function( cookie, index ) {
						checked = get( cookie ) ? true : false;
						$("input[name='cc-cookie'][value='"+cookie+"']").prop('checked',checked);
					});

					if ( $('#cookie-consent').data('mode') !== 'banner' || settings === true ) {

						if ( options.effect == 'slide' ) {
							$( '#cookie-consent' ).slideDown( 300 );
						} else if ( options.effect == 'fade' ) {
							$('#cookie-consent' ).fadeIn( 300 );
						} else {
							$( '#cookie-consent' ).show();
						}

					} else {

						$( '#cookie-banner' ).addClass('show');

						var $url = "";

						$( document ).on( 'click', 'a[target!="_blank"]:not(.cc-btn-accept, .cc-btn-param)', function(e) {

							if ( get('done') ) {
								return;
							} else {
								$url = $(this).attr('href');

								e.preventDefault();
								cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';
								cookieAccept( $url );

							}
						});

						$( "[data-link]" ).unbind('click');
						$( document ).on( 'click', '[data-link]', function(e) {
							if ( get('done') ) {
								return;
							} else {

								$url = $(this).attr('data-link');
								cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';
								cookieAccept( $url );
								$( "[data-link]" ).bind('click');
							}
						});

						$( document ).on( 'click', 'form:not(#cc-form)', function(e) {
							if ( get('done') ) {
								return;
							} else {
								cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';
								cookieAccept();
							}
						});


					}

					// S’il n'y a pas de cookie, on recupère les valeurs par défaut réglé en BO
					if ( !$.cookie( 'cookie_consent' ) ) {

						cookieValue = 'done:0!all:0';

						if ( $('#cookie-consent').data('mode') !== 'banner' ) {
							$('#cookie-consent .cc-main').show();
							$('#cookie-consent .cc-param').hide();
						}

						$('.cc-type').each(function() {
							cookieValue += '!'+$(this).attr('data-type')+':'+$(this).attr('data-default');

							if ( $(this).data('default') == '2' ) {
								$(this).find('input[type="checkbox"]').prop('disabled', true );
							}

							if ( $(this).data('default') == '1' || $(this).data('default') == '2' ) {
								$(this).find('input[type="checkbox"]').prop('checked', true );
							}
						});



						// Si le cookie est présent, on bloque simplement les champs obligatoire
					} else {

						$('.cc-type').each(function() {
							if ( $(this).data('default') == '2' ) {
								$(this).find('input[type="checkbox"]').prop('disabled', true );
							}
						});

					}

				} // Success
			}); // .ajax


		}; // function showbar;

		// Lecture du cookie
		var get = function(type) {

			if ( $.cookie( 'cookie_consent' ) ) {
				cookies = $.cookie( 'cookie_consent' ).split( '!' );

				for ( var i = 0; i < cookies.length; i++ ) {

					cookie = cookies[i].split( ':' );

					if ( cookie[0] == type ) {
						return parseInt( cookie[1] );
					}
				}
			}
			return 0;

		};

		// Fermeture de la modale et rechargement
		var hideBar = function() {

			if ( options.effect == 'slide' ) {
				$('#cookie-consent').slideUp(300, function() { $(this).remove(); });
			} else if ( options.effect=='fade') {
				$('#cookie-consent').fadeOut(300, function() { $(this).remove(); });
			} else {
				$('#cookie-consent').hide(0, function() { $(this).remove(); });
			}

			$('#cookie-banner').removeClass('show');

		};

		// Acceptation des cookies
		var cookieAccept = function( url ) {


			var write = false; // booleen pour dire si il y a eu au moins un changement de valeur de cookie
			var done  = get('done'); // est-ce que la validation a ete faite
			var i     = 0;

			// On regarde si on doit mettre à jour le cookie
			for ( i = 0; i < options.cookieList.length; i++ ) {
				if(update[options.cookieList[i]] || !done) {
					write = true;
				}
			}


			// Maj du cookie
			if ( write ) {

				$.cookie( 'cookie_consent', cookieValue, {
					expires: $( '#cookie-consent' ).data( 'expiration' ), path: '/'
				});

				// On injecte le code si maj cookie / cookie
				for ( i = 0; i < options.cookieList.length; i++ ) {
					if(update[options.cookieList[i]] || !done) {
						if(get(options.cookieList[i])) {
							$('body').append( cookieJS[options.cookieList[i]] );
						}
					}
				}
			}

			hideBar();

			if ( url ) {
				setTimeout( function() {
					if(url == window.location.href)
						location.reload();
					else
						location.href = url;
				}, 1000);
			}

		};

		if ( get('done') ) {
			cookieValue = $.cookie( 'cookie_consent' );
		} else {
			showBar();
		}

		// EVENT CHECK
		$( document ).on( 'click', '#cookie-consent input[type="checkbox"]', function() {
			var checked  = $(this).prop('checked');
			var accepted = get($(this).attr('id'));
			update[$(this).attr('id')] = (checked != accepted);
		});

		// BTN
		$( document ).on( 'click', '#cookie-consent .cc-btn-accept', function(e) {
			e.preventDefault();

			cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';

			cookieAccept();
		});

		// BTN on banner
		$( document ).on( 'click', '#cookie-banner .cc-btn-accept', function(e) {
			e.preventDefault();

			cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';
			$('#cookie-banner').removeClass('show');
			cookieAccept();
		});

		$( document ).on('click', '#cookie-consent .cc-btn-done', function(e) {
			e.preventDefault();

			cookieValue = 'done:1!all:0';
			$( 'input[name="cc-cookie"]' ).each( function( index ) {
				cookieValue += '!' + $(this).val() + ':' + (0 + $(this).is( ':checked' ) );
			});

			cookieAccept();
		});

		$( document ).on('click', '#cookie-consent .cc-btn-param', function(e) {
			e.preventDefault();
			$('#cookie-consent .cc-main').hide();
			$('#cookie-consent .cc-param').show();
		});

		$( document ).on('click', '#cookie-banner .cc-btn-param', function(e) {
			e.preventDefault();
			$('#cookie-banner').removeClass('show');
			setTimeout(function() {
				$('#cc-mcube-rgpd').remove();
				showBar( true );
			}, 300)
		});

		// LINK
		$( '.open-rgpd-settings' ).click(function(e) {
			e.preventDefault();
			showBar( true );
		});



	};

	$.cookieConsent({
		fixed:      true,
		zindex:     10000
	});


})(jQuery);