
(function($){

    $.fn.mcubeGmaps = function( options ){

		var defauts = {
			bounds:  true, // active le centrage automatiquement de la map afin d'afficher tous les points dans le viewport
			overlay: true, // active l'apparition de la 'popup' au click sur le pointeur
			styles : null  // personnalisation des styles la map
		};

   		var parametres = $.extend(defauts, options);

   		if( typeof GMaps == "undefined" ){
			console.log('ERREUR GMAPS : activer GMaps dans functions.php','#FA3348','#ffffff');
   		} 

   		return this.each(function(){

   			var $map    	= $(this);
			var map         = false;
			var mapLat      = $map.data('lat');
			var mapLng      = $map.data('lng');
			var mapZoom     = ( $map.data('zoom') || 12 );
			var markers     = $('.map-markers', $map);
			var pointer     = $map.data('pointer');
			var pointerSize = $map.data('pointer-size');
			var bounds      = null;

			if( !mapLat ){
				console.log('ERREUR GMAPS : définir une latitude (data-lat)','#FA3348','#ffffff');
			}

			if( !mapLng ){
				console.log('ERREUR GMAPS : définir une longitude (data-lng)','#FA3348','#ffffff');
			}

			if( markers.length == 0 ){
				console.log('ERREUR GMAPS : aucun point de défini','#FA3348','#ffffff');
				console.log('<div class="map-markers" data-lat="" data-lng=""> contenu html </div>','#336bfa','#ffffff');
			}

			if( parametres.bounds ){
				bounds = new google.maps.LatLngBounds();
			}

			map = new GMaps({
				div:         "#"+$(this).attr('id'),
				zoom:        mapZoom,
				// maxZoom:     13,
				lat:         mapLat,
				lng:         mapLng,
				scrollwheel: false,
				zoomControl: true,
		        panControl : false,
				options: {
					'styles': parametres.styles
				}
			});

			/// Fonctions au resize pour recentrer la map
			google.maps.event.addDomListener(window, 'resize', function() {
				if( parametres.overlay ){
					map.setCenter(marker_lat, marker_lng);
				}else if( parametres.bounds ){
					map.panToBounds(bounds);
					map.fitBounds(bounds);
		    	}else{
		    		map.setCenter(mapLat, mapLng);
		    	}
		    });

			/// Personnalisation du pointer
			if( pointer ){
				if( !pointerSize ){
					console.log('ERREUR GMAPS : spécifier les dimensions du pointer (data-pointer-size="WxH")','#FA3348','#ffffff');
				}
				imageSize   = pointerSize.split('x');
				imageWidth  = parseInt(imageSize[0]);
				imageHeight = parseInt(imageSize[1]);

				var icon = {
					url:        pointer,
					size:       new google.maps.Size( imageWidth , imageHeight ),
					origin:     new google.maps.Point( 0 , 0 ),
					anchor:     new google.maps.Point( imageWidth/2 , imageHeight ),
					scaledSize: new google.maps.Size( imageWidth , imageHeight )
				};

			}

			/// Ajout des markers
			$.each(markers, function(i,program){

				$this           = $(this);
				var custom_icon = null;

				if( $this.data('pointer') ){

					var custom_icon_url  = $this.data('pointer');
					var custom_icon_size = $this.data('pointer-size');

					custom_icon          = {
						url:        custom_icon_url,
						size:       new google.maps.Size( imageWidth , imageHeight ),
						origin:     new google.maps.Point( 0 , 0 ),
						anchor:     new google.maps.Point( imageWidth/2 , imageHeight ),
						scaledSize: new google.maps.Size( imageWidth , imageHeight )
					};

				}

				var myMarker = map.addMarker({
					id:      $this.data('id'),
					lat:     $this.data('lat'),
					lng:     $this.data('lng'),
					content: $this.html(),
					icon:    ( custom_icon || icon ),
					click:   function(element){
			            if( parametres.overlay ){
			            	mapLoadOverlay(element);
			            }
					}
				});

				if( parametres.bounds ){
					bounds.extend( myMarker.getPosition() );
					markers.push( myMarker );
				}

			});

			/// Recentre la map pour afficher tous les markers dans le viewport
			if( parametres.bounds ){
				map.panToBounds(bounds);
				map.fitBounds(bounds);
			}

			/// Affichage des infos bulles
	       	var mapLoadOverlay = function(element){

	       		overlay = true;

				marker     = element;
				position   = marker.getPosition();
				marker_lat = position.lat();
				marker_lng = position.lng();

				contenu = '<div class="map-overlay"><div class="map-overlay-content"><a href="#" class="map-overlay-close">x</a>';
				contenu += '<div class="map-overlay-inner">'+marker.content+'</div>';
				contenu += '</div></div>';

				map.removeOverlays();

				map.setCenter(marker_lat, marker_lng);
				
				var imgLoad = imagesLoaded( $(contenu) );
				imgLoad.on( 'always', function() {
					map.drawOverlay({
						lat:              marker_lat,
						lng:              marker_lng,
						verticalAlign:    'center',
						horizontalOffset: 20,
						layer:            'floatPane',
						content:          contenu
					});
				});

				$('.map-overlay a:not(.map-overlay-close)').unbind('click').click(function(e) {
					e.preventDefault();
					linkHandler( $(this) );
				});

				$(document).on('click touchstart', '.map-overlay-close', function(e) {
					e.preventDefault();
			    	map.removeOverlays();
			    	overlay = false;

			    	if( parametres.bounds ){
				  		map.panToBounds(bounds);
						map.fitBounds(bounds);
					}
			    });

			}

       	});

    };

})(jQuery);